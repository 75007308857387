import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Nav, Button, Container, Row, Col} from 'react-bootstrap'


function SiteNav()
{
  return (
      <Nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <a className="navbar-brand" href="#">Callan W | Developer</a>
        <Button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01"
                aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </Button>

        <div className="collapse navbar-collapse" id="navbarColor01">
        </div>
      </Nav>
  )
}

function CardListItem(props)
{
    return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
            {props.item}
            <span className="badge badge-primary badge-pill">{props.score && props.score}</span>
        </li>
    )
}


function App() {
  return (
    <React.Fragment>
        <SiteNav/>

        <div className={"container"} >

            <div className="jumbotron mt-3">
                <h1 className="display-4">Hello, world!</h1>

                <p className="lead">I'm Callan Winfield, Full stack developer and Senior Software engineer at <a href={"http://orbtechnologies.com.au/"} target={"_blank"}>Orb Technologies</a></p>
                {/*<hr className="my-4" />
                    <p>Creating, developing and maintaining projects, it's a wild ride. </p>
                <p>Here are some tools used to get things done, scored out of 10 for how often they have been used in the last year or so.</p>

                <Row>
                    <Col>
                        <p className={"lead"}>Languages</p>
                        <ul className="list-group">
                            <CardListItem item={"Javascript"} score={10}/>
                            <CardListItem item={"Typescript"} score={10}/>
                            <CardListItem item={"PHP"} score={10}/>
                            <CardListItem item={"Python"} score={5}/>
                            <CardListItem item={"c#"} score={2}/>
                            <CardListItem item={"c++"} score={1}/>
                            <CardListItem item={"Ruby"} score={1}/>
                        </ul>
                    </Col>
                    <Col>
                        <p className={"lead"}>Front end</p>
                        <ul className="list-group">
                            <CardListItem item={"Angular"} score={10}/>
                            <CardListItem item={"React"} score={7}/>
                            <CardListItem item={"Jquery"} score={6}/>
                        </ul>
                    </Col>
                    <Col>
                        <p className={"lead"}>Back end</p>
                        <ul className="list-group">
                            <CardListItem item={"Express JS"} score={10}/>
                            <CardListItem item={"Nest JS"} score={10}/>
                            <CardListItem item={"Apache/PHP"} score={10}/>
                            <CardListItem item={".net core"} score={2}/>
                        </ul>
                    </Col>
                </Row>

                <Row className={"mt-3"}>
                    <Col>
                        <p className={"lead"}>Database & Queues</p>
                        <CardListItem item={"MySQL/Google Cloud SQL"} score={10}/>
                        <CardListItem item={"Redis"} score={6}/>
                        <CardListItem item={"RabbitMQ"} score={6}/>
                        <CardListItem item={"MongoDB"} score={2}/>
                    </Col>
                    <Col>
                        <p className={"lead"}>Technologies</p>
                        <ul className="list-group">
                            <CardListItem item={"NodeJS"} />
                            <CardListItem item={"Google Cloud Compute / Linux VM's"}/>
                            <CardListItem item={"Google Cloud App Engine"} />
                            <CardListItem item={"Docker"} />
                        </ul>
                    </Col>
                </Row>*/}

                <div className={"mt-3"}>
                    <p>Need to know more? check out my <a className="btn btn-primary btn-lg" href="https://www.linkedin.com/in/callan-winfield-74765545/" role="button" target={"_blank"}>LinkedIn</a></p>
                </div>
            </div>



        </div>
    </React.Fragment>
  );
}

export default App;
